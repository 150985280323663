import React from 'react';
import PropTypes from 'prop-types';

import { UNAUTHORIZED, NOT_FOUND } from '../const/httpCodes';
import Api from '../helpers/api';
import RollbarLogger from '../helpers/RollbarLogger';
import ApiException from '../exceptions/ApiException';
import ErrorPage from '../components/error/ErrorPage';
import NotFoundPage from '../components/error/NotFoundPage';
import { SITE_INFO_SETTINGS } from '../helpers/constants';

function CustomError({ statusCode }) {
  if (statusCode === NOT_FOUND) {
    return <NotFoundPage />;
  }

  return <ErrorPage />;
}

/**
 * @param {import('next').NextPageContext} ctx
 * @return {{statusCode: number}}
 */
CustomError.getInitialProps = async ({ req, res, err, query }) => {
  let statusCode = NOT_FOUND;

  if (err?.statusCode) {
    statusCode = err.statusCode;
  } else if (res?.statusCode) {
    statusCode = res.statusCode;
  }

  // do not log 401 and 404 errors to Rollbar
  if ([UNAUTHORIZED, NOT_FOUND].includes(statusCode) === false) {
    const apiService = new Api({ domain: req ? req.headers.host : null });
    let settings = {};

    try {
      settings = query?.tableHash
        ? await apiService.getQrHubSettings(query.tableHash)
        : (await apiService.getSiteInfo([SITE_INFO_SETTINGS])).settings;
    } catch (error) {
      // We leave "catch" block empty because during the settings retrieval process,
      // there might be an "Unauthorized" error, and we don't want catch.
    }

    RollbarLogger.configure(settings);

    if (err instanceof ApiException) {
      RollbarLogger.error(err, req, {
        ...err.payload,
        statusCode,
        errorStatus: err?.statusCode,
        resStatusCode: res?.statusCode,
      });
    } else {
      RollbarLogger.critical(err, req, { statusCode });
    }
  }

  return { statusCode };
};

CustomError.propTypes = {
  statusCode: PropTypes.number.isRequired,
};

export default CustomError;
