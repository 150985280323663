import React from 'react';
import { useRouter } from 'next/router';
import { faMagnifyingGlass } from '@fortawesome/pro-duotone-svg-icons';
import l from '../../helpers/lang';
import ErrorPage from './ErrorPage';

export default function NotFoundPage() {
  const router = useRouter();

  return (
    <ErrorPage
      icon={faMagnifyingGlass}
      title={l('delivery.page/404.title')}
      text={l('delivery.page/404.description')}
      action={() => router.push('/')}
      actionText={l('delivery.page/404.goToMainPage')}
    />
  );
}
